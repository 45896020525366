import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { v4 as uuid} from 'uuid';
import { Subscription, throwError } from 'rxjs';
import { catchError, retry, finalize } from 'rxjs/operators';
import { Permiso } from './commons/interfaces/permiso.interface';
import swal from 'sweetalert2';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public url : string = environment.endpoint;

  private nombre: string = "";

  private perm: Permiso[] = null;

  public errorText: string  = null;

  public errorStatus: string  = 'lllll';

  public blockUI: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    
    this.initToken();
    // this.fKeepAlive().add(() => {
    //   if ( !this.fIsLogin() ) {
    //     this.navigateByUrl('/pages/login')
    // }
    // });
  }

  private initToken(): void {
    if ( !this.token ) {
      // Primera vez que maquina actual ingresa
      this.token = uuid();
      this.usuarioId = "0";
    }
  }

  public get usuarioNombre(): string{
    return this.nombre;
  }

  public set usuarioNombre(nombre: string){
    this.nombre = nombre;;
  }

  public get usuarioId(): string {
    return localStorage.getItem('id_usuario');
  }

  public set usuarioId(id_usuario: string){
    localStorage.setItem('id_usuario', id_usuario);
  }

  public get token(): string {
    return localStorage.getItem('uuid');
  }

  public set token(token: string){
    localStorage.setItem('uuid', token);
  }

  public get idEntidadTipo(): string {
    return localStorage.getItem('id_entidad_tipo');
  }

  public set idEntidadTipo(idEntidadTipo: string){
    localStorage.setItem('id_entidad_tipo', idEntidadTipo);
  }

  public getParam(param:string): String {
    return this.route.snapshot.paramMap.get(param);
  }

  public navigateByUrl(url:string): void {
    this.router.navigateByUrl(url);
  }

  public unblockUI(): void {
    this.blockUI=false;
  }

  /**
   * @description Funcion que indica si un modulo es accesible puede ser ejecutado
   */
  public isAuth(api: string, modulo: string, peticion: string): boolean{
    let permission = this.perm.find(permission => permission.api === api && permission.modulo === modulo && permission.peticion === peticion);
    if( permission ) {
      //Tiene permisos de ejecucion
      return true;
    } else {
      //No tiene permisos de ejecucion
      this.router.navigateByUrl('/pages/login');
      swal.fire({
        title: `Acceso Denegado`,
        text: 'No tiene permisos para ingresar a este modulo, solicite los permisos al administrador de su plataforma',
        buttonsStyling: false,
        customClass:{
          confirmButton: "btn btn-warning",
        },
        icon: "warning"
      });
      return false;
    }
  }

  /**
   * @description Funcion que indica si un perm puede ser ejecutado
   * @param perm Permiso a consultar
   * @returns Si tiene o no permiso
   */
  public getAuth(api: string, modulo: string, peticion: string): boolean {
    if (this.perm) {
      let permission = this.perm.find(permission => permission.api === api && permission.modulo === modulo && permission.peticion === peticion);

      if( permission ) {
        //Tiene permisos 
        return true;
      } else {
        //No tiene permisos 
        return false;
      }
    } else {
      return false;
    }
    
  }

  /**
   * @description Funcion para consumir servicio
   * @param xPet Nombre del servicio que se va a consumir
   * @param jReq Peticion que se enviara al servicio
   */
  public ws(xPet:string,jReq:any) {
    this.blockUI=true;
    //Reinicia el mensaje de error
    this.errorText = null;
    this.errorStatus = null;

    let url: string = this.url;

    var cUrl:string = url+"/"+xPet;

    //Si esta en servidor ajedo recordar el Access-Control-Allow-headers CORS
    

    return this.http.post(cUrl,jReq,{ observe: 'response', headers:{'Content-Type':  'application/json','Token': this.token}})
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError), // then handle the error
        finalize(()=> this.unblockUI())
      );
  }

  /**
   * @description Funcion para consumir servicio sin bloquear interfaz
   * @param xPet Nombre del servicio que se va a consumir
   * @param jReq Peticion que se enviara al servicio
   * @returns 
   */
  public wsBack(xPet:string,jReq:any) {
    //Reinicia el mensaje de error
    this.errorText = null;
    this.errorStatus = null;

    let url: string = this.url;

    var cUrl:string = url+"/"+xPet;
    //Si esta en servidor ajedo recordar el Access-Control-Allow-headers CORS
    return this.http.post(cUrl,jReq,{ observe: 'response', headers:{'Token': this.token}})
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleErrorBack)
      );
  }

  public fKeepAlive(): Subscription {
    //Verifica el estado de la iSession
    return this.ws('Auth/v1.0/KeepAlive', {})
    .subscribe(
      resp =>{
        var body:any = resp.body;
        this.usuarioId = body.id_usuario;
        this.nombre = body.nombre;
        this.perm = body.perm;
        this.idEntidadTipo = body.id_entidad_tipo;
      });    
  }

  public fLogOut(): void {
    this.alert('Cerrando Terminal','Un momento por favor...',2000);
    this.ws('Auth/v1.0/Logout',{})
      .subscribe(
        resp =>{
            var body: any = resp.body;
            this.usuarioId = "0";
            this.nombre = 'NoBody';
            this.idEntidadTipo = "";
            this.navigateByUrl('/pages/login');
            
        });
  }

  public alert(title:string, text:string,timer:number): void{
    swal.fire({
      title: title,
      text: text,
      timer: timer,
      showConfirmButton: false
    });
  }

  public fIsLogin(): boolean {
    if(this.usuarioId === "0") {
      return false;
    } else {
      return true;
    }
  }

  public handleErrorBack(error: HttpErrorResponse) {
    console.error(`WS ERROR: ${error.status} - ${error.statusText}`);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Un error ocurrio:', error.error.message);
    } else {
      console.error(error.error);   
    }
    return throwError(
      'Algo salio mal, intenta nuevamente');
  }

  public handleError(error: HttpErrorResponse) {
    console.error(`WS ERROR: ${error.status} - ${error.statusText}`);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Un error ocurrio:', error.error.message);
    } else {
      console.error(error.error);   
    }
    var errorStatusAlert: string = "";
    var errorTextAlert: string = "";

    switch(error.status) {
      case 0:
        errorStatusAlert = 'No hay conexión';
        errorTextAlert = 'No se tiene conexión con el servidor, revise su conexión a la red';
        break;
      case 400:
        errorStatusAlert = 'Solicitud Erronea';
        errorTextAlert = 'La petición no cumple con los requisitos';
        break;
      case 401:
        errorStatusAlert = 'Sin Autorización';
        errorTextAlert = error.error.oDes;
        break;
      case 404:
        errorStatusAlert = 'No Existe';
        errorTextAlert = 'El recurso no se ha encontrado en el servidor';
        break;
      case 409:
        errorStatusAlert = 'Problema en PGSQL';
        errorTextAlert = 'Existe un problema en la ejecución de la Base de Datos';
        break;
      case 500:
        errorStatusAlert = 'Error Interno';
        errorTextAlert = 'Existe un error en el lado del servidor';
        break;
      default:
        errorStatusAlert = error.statusText;
        break;
    }
    swal.fire({
      title: `Error ${error.status} - ${errorStatusAlert}`,
      text: errorTextAlert,
      buttonsStyling: false,
      customClass:{
        confirmButton: "btn btn-danger",
      },
      icon: "error"
    });

    // //Reiniciamos el mensaje de error
    // this.errorText = null;
    // this.errorStatus = null;

    // // return an observable with a user-facing error message
    return throwError(
      'Algo salio mal, intenta nuevamente');
  };

  errorNotification(from: any, align: any, msg: string) {
    //const type = ['', 'danger'];
    //const color = Math.floor((Math.random() * 6) + 1);
    $.notify({
      icon: "pe-7s-info",
      message: msg
    },{
        type: 'danger',
        timer: 4000, 
        z_index: 3031,
        placement: {
            from: from,
            align: align
        }
    });

  }
  successNotification(from: any, align: any, msg: string) {
    $.notify({
      icon: "pe-7s-like2",
      message: msg

    }, {
      type: 'success',
      timer: 4000,
      placement: {
        from: from,
        align: align
      }
    });
  }


}
