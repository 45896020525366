<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/eddas/usuario/default-avatar.png" />
    </div>
  </a>
  <a href="/pages/login" class="simple-text logo-normal">
    PDA
  </a>
</div>


<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img src="./assets/eddas/usuario/default-avatar.png" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          Usuario
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link"
            (click)="cerrarSesion();">
              <span class="sidebar-mini">S</span>
              <span class="sidebar-normal">Cerrar Sesion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" id="{{menuitem.collapse}}-p"
        *ngIf="menuitem.type === 'sub'" (click)="updatePS();expandOrCollapseMenu(menuitem.collapse)" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>